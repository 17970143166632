
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';

// core components
import App from "./App.js";


import "./assets/css/livability.css?v=1.9.0";
//import 'mapbox-gl/dist/mapbox-gl.css';

ReactDOM.render(
  <Router >
    <Switch>
      <Route path="/home" component={App} />
      <Route path="/dashboard" component={App} />
      <Route path="/resources" component={App} />
      <Route path="/contact" component={App} />
      <Route path="/cp" component={App} />
      <Redirect from="/" to="/home" />
    </Switch>
  </Router>,
  document.getElementById("root")
); 

