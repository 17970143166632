import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import GridItem from "./GridItem.js";
import GridContainer from "./GridContainer.js";

import {infoColor} from "../assets/jss/livability.js";
import states from '../json/states.js';

import {pillars} from '../json/pillars_indicators.js';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

  const selectStyles = makeStyles({
    container: {
      marginTop: "10%",
    },
    formControl: {
      minWidth: 120,
    },
    label: {
      color: infoColor[0],
      "&.Mui-focused": {
        color: infoColor[0],
      },
    },
    select: {
      "&:hover": {
        borderBottomColor: infoColor[0]
      },
      "&:before": {
        borderBottomColor: infoColor[0],
      },
      "&:after": {
        borderBottomColor: infoColor[0],
      },
      "& .MuiSvgIcon-root": {
        color: infoColor[0],
      },
    },
    
  });

  const toggleStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        borderColor: infoColor[0],
        color: infoColor[0],
        whiteSpace: 'nowrap'
    },
    label: {
        color: infoColor[0],
        width: '100%'
    },
    selected: {
        backgroundColor: 'red'
    }

  }));
  
export default function Selectors(props){
    const classes3 = toggleStyles();
    const classes4 = selectStyles();

    states.sort(function(a, b) {
      if (a.name < b.name) {
        return  -1;
      }
      else if(a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return (
            <GridContainer direction="column" justify="space-between" spacing={5}>
            <GridItem style={{margin:'15px'}} xs={12} >
            <FormControl variant="filled">
              <InputLabel shrink id="statelabel" style={{color:infoColor[0]}}>
              Select a state
              </InputLabel>
              <Select
                id="state"
                value={props.filters.state}
                onChange={(e) => props.changeFilters(e, e.target.value, "state")}
                displayEmpty
                className={classes4.select}
                style={{width:280, border:'1px solid infoColor[0]'}}
              >
                <MenuItem value="">
                  All India
                </MenuItem>
                {
                  states.map(state=> {
                    return (<MenuItem key={state.name} value={state.name}>{state.name}</MenuItem>);
                  })
                }
              </Select>
              </FormControl>
            </GridItem>

            { props.filters.state !== "" ? 
            <GridItem style={{margin:'15px'}} xs={12} >
                <ToggleButtonGroup key="scale" name="scale" value={props.filters.scale} exclusive >
                <ToggleButton name="scale" value="" onClick={(e) => props.changeFilters(e,"", "scale" )}
                            classes={props.filters.scale === "" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                            style={props.filters.scale === "" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">National scale</ToggleButton>

                <ToggleButton name="scale" value="_s" onClick={(e) => props.changeFilters(e, "_s", "scale")}
                        classes={props.filters.scale === "_s" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                        style={props.filters.scale === "_s" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">State scale </ToggleButton>

                </ToggleButtonGroup>
            </GridItem>
             : null }

            <GridItem style={{margin:'15px'}} xs={12} >
            <FormControl variant="filled">
            <InputLabel shrink id="pillarlabel" style={{color:infoColor[0]}}>
              Select a pillar
            </InputLabel>
            <Select
              id="pillar"
              value={props.filters.pillar}
              onChange={(e) => props.changeFilters(e, e.target.value, "pillar")}
              displayEmpty
              className={classes4.select}
              style={{width:280, border:'1px solid infoColor[0]'}}
            >
              <MenuItem value="">
                Overall
              </MenuItem>
              {
                pillars.map(p => {
                  return (<MenuItem key={p.key} value={p.key}>{p.value}</MenuItem>);
                })
              }
            </Select>
            </FormControl>
            
            </GridItem>

            <GridItem style={{margin:'15px'}} xs={12} >
                <ToggleButtonGroup key="display" name="display" value={props.filters.display} exclusive >
                <ToggleButton name="display" value="map" onClick={(e) => props.changeFilters(e,"map", "display" )}
                            classes={props.filters.display === "map" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                            style={props.filters.display === "map" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">Map</ToggleButton>

                <ToggleButton name="display" value="chart" onClick={(e) => props.changeFilters(e, "chart", "display")}
                        classes={props.filters.display === "chart" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                        style={props.filters.display === "chart" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">Chart </ToggleButton>

                <ToggleButton name="display" value="raw" onClick={(e) => props.changeFilters(e, "raw", "display")}
                        classes={props.filters.display === "raw" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                        style={props.filters.display === "raw" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">Data </ToggleButton>
                </ToggleButtonGroup>
                
            </GridItem>

            <GridItem style={{margin:'15px', display:'flex', justifyContent:'flex-start', alignItems:'center'}} xs={12} >
                <ToggleButtonGroup key="subset" name="subset" value={props.filters.subset} exclusive >
                { props.filters.state !== "" || props.filters.display !== "chart" ?   
                <ToggleButton name="subset" value="" onClick={(e) => props.changeFilters(e,"", "subset" )}
                            classes={props.filters.subset === "" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                            style={props.filters.subset === "" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">All</ToggleButton>
                 : null }
  
                <ToggleButton name="subset" value="top" onClick={(e) => props.changeFilters(e, "top", "subset")}
                        classes={props.filters.subset === "top" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                        style={props.filters.subset === "top" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">Top</ToggleButton>

<               ToggleButton name="subset" value="bottom" onClick={(e) => props.changeFilters(e, "bottom", "subset")}
                        classes={props.filters.subset === "bottom" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                        style={props.filters.subset === "bottom" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">Bottom</ToggleButton>

                </ToggleButtonGroup>
                { props.filters.subset !== "" ? 
                <div style={{paddingLeft:'15px'}}>
                  <FormControl >
                  <Select
                    id="count"
                    variant="standard"
                    value={props.filters.count}
                    onChange={(e) => props.changeFilters(e, e.target.value, "count")}
                    className={classes4.select}
                    style={{border:'1px solid infoColor[0]'}}
                  >
                    {
                      [5, 10, 15, 20].map(c=> {
                        return (<MenuItem key={c+"item"} value={c}>{c}</MenuItem>);
                      })
                    }
                  </Select>
                  </FormControl>
                  </div>
                : null}
            </GridItem>
            </GridContainer>
              
    );
}