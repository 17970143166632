import React from "react";
import circle_image from '../assets/img/circle_image.png';
import '../assets/css/btn.css'
import ComingSoon from './ComingSoon.js'
 
export default function Home(props){

      //comment this for live
    /* if(window.location.href.indexOf("rqol.developmentintelligenceunit.in") !== -1){
        return (<ComingSoon/>);
    } */

    return (
            <div style={{background:'white'}}>
                <div class="midContent" >
                        <div class="roatateImg">
                            <img src={circle_image} alt="Rural Quality of Life Flywheel"/>
                        </div>
                        <div style={{paddingBottom: '10px', fontFamily: "Poppins, Roboto", fontSize: 'medium', textAlign:'justify', textAlignLast: 'left'}}>
                            The Rural Quality of Life Dashboard has been developed by Development Intelligence Unit. 
                            With the aim to come out with a planning and monitoring tool for creating a development roadmap for districts, this index quantifies and ranks 707 rural districts of India on the basis of providing a decent quality of life for its citizenry. 
                            This Rural Quality Of Life Dashboard represents each district in the country by an overall score, which in turn is derived from the district's performance across 9 different pillars, viz. Agriculture, Economic Ability & Employment, Education, Gender, Governance, Health & Nutrition, Infrastructure & Amenities, Social Security and Sustainability & Climate Resilience.
                        </div>
                        <div style={{padding:'10px'}}>
                            <a href="/dashboard" class="blueBtn" style={{fontFamily: "Poppins, Roboto", fontSize: 'large'}} >Explore the Dashboard</a>
                        </div>    
                    </div>
                
                   
            </div>
        ) 
}