import React from 'react';
import {infoColor} from "../assets/jss/livability.js";
import Popover from '@material-ui/core/Popover';
import Link from '@material-ui/core/Link';

export default function Legend(props){

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


    return (
        <div>
          <Link href="#" style={{color:infoColor[0]}} onClick={handleClick} rel="noopener noreferrer">
           {`📝Definition of '` + props.keyword + `'`}
          </Link>
        
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
         <div style={{backgroundColor:'lightgray', padding: '5px 10px', fontFamily: "Arial", fontSize: 'small'}}>
            { props.keyword === "Deprived" ?
              <p>
                The <a href="https://secc.gov.in/getAllCategoryDeprivationNationalReport.htm" style={{color:infoColor[0]}} target="_blank">Socio Economic and Caste Census (SECC)</a>  has defined 7 deprivation criteria, which are: 
                <ol>
                    <li>Only one room with kucha walls and kucha roof </li>
                    <li>No adult member between age 16 to 59</li>
                    <li>Female headed households with no adult male member between age 16 to 59</li>
                    <li>Disabled member and no able bodied adult member</li>
                    <li>SC/ST households</li>
                    <li>No literate adult above 25 years </li>
                    <li>Landless households deriving major part of their income from manual casual labour</li>
                </ol>

                SECC refers to households being deprived or being categorised as a deprived household if the household meets any one deprivation criteria as well as multiple deprivation criteria.

              </p>
            : 
            <p>
              Please refer to <a href="https://nfsa.gov.in/portal/NFSA-Act" style={{color:infoColor[0]}} target="_blank">NFSA Act</a> for eligibility definition. 
            </p>
          } 
          </div>
      </Popover>
      </div>


    )
}