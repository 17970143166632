import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {infoColor} from "../assets/jss/livability.js";

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';


const useStyles = makeStyles((theme) => ({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      border: '1px solid '+infoColor[0],
      backgroundColor: 'white', 
      '&:hover': {
        backgroundColor: 'white',
      },
      marginRight: 5,
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(1),
        width: 'auto',
      },
      fontFamily: 'Scope One',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'red'
    },
    inputRoot: {
      color: 'default',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      textAlign: 'left',
      // vertical padding + font size from searchIcon
       paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '20ch',
        '&:focus': {
          width: '30ch',
          color: '#303f9f'
        },
      }, 
    },
  }));

export default function SearchField (props){
    const classes = useStyles();

    const [val, setVal] =  React.useState("");

    const onChange = (value) => {
        console.log("Inside onChange "+value.length)
        setVal(value);
        props.onChange(value);
    }

    return (
        <div className={classes.search}>
        <div className={classes.searchIcon}>
            <SearchIcon />
        </div>
        <InputBase
            placeholder="District search"
            classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
            }}
            onChange={(e) => onChange(e.target.value)}
            endAdornment={
                val !== "" ?
                <InputAdornment position="end">
                    <IconButton onClick={(e) => onChange("")}><ClearIcon style={{color:'red'}}/></IconButton>
                </InputAdornment> : null
            }
            value={val}
        />
        </div>
    )
}