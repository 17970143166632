import React, { PureComponent } from 'react';
import { PieChart, Pie, Legend, Cell } from 'recharts';


const RADIAN = Math.PI / 180;

export default class PierChart extends PureComponent {

  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
     return (
      <text x={x} y={y} fill="#000000" textAnchor="middle" dominantBaseline="center">
        {`${(percent * 100).toFixed(0)}%`}
    </text>
    ); 
  };

  renderLegend = (props) => {

    return (
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr'}}>
        <div style={{display:'flex', justifyContent:'center', fontSize:'x-small', backgroundColor:this.props.colors[0], color: 'black'}}>0-20</div>
        <div style={{display:'flex', justifyContent:'center', fontSize:'x-small', backgroundColor:this.props.colors[1], color: 'black'}}>20-40</div>
        <div style={{display:'flex', justifyContent:'center', fontSize:'x-small', backgroundColor:this.props.colors[2], color: this.props.scale === "" ? 'black' : 'white'}}>40-60</div>
        <div style={{display:'flex', justifyContent:'center', fontSize:'x-small', backgroundColor:this.props.colors[3], color: 'white'}}>60-80</div>
        <div style={{display:'flex', justifyContent:'center', fontSize:'x-small', backgroundColor:this.props.colors[4], color: 'white'}}>80-100</div>        
        <div style={{display:'flex', justifyContent:'center', fontSize:'x-small', backgroundColor:'#efefef', color: 'black'}}>NA</div>
      </div>
    );
  }

  renderLegend1 = (props) => {

    return (
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'}}>
        <div style={{display:'flex', justifyContent:'center', fontSize:'x-small', backgroundColor:this.props.colors[0], color: 'black'}}>0-20</div>
        <div style={{display:'flex', justifyContent:'center', fontSize:'x-small', backgroundColor:this.props.colors[1], color: 'black'}}>20-40</div>
        <div style={{display:'flex', justifyContent:'center', fontSize:'x-small', backgroundColor:this.props.colors[2], color: this.props.scale === "" ? 'black' : 'white'}}>40-60</div>
        <div style={{display:'flex', justifyContent:'center', fontSize:'x-small', backgroundColor:this.props.colors[3], color: 'white'}}>60-80</div>
        <div style={{display:'flex', justifyContent:'center', fontSize:'x-small', backgroundColor:this.props.colors[4], color: 'white'}}>80-100</div>        
      </div>
    );
  }

  render() {
    const score_param = this.props.pillar + 'score' + this.props.scale;
    let i=0, j=0, k=0, l=0, m = 0, n=0;
    this.props.ranking.forEach((entry) => {
      if(!entry[score_param]){
        n++;
      }
      else if(entry[score_param] > 80){
        i++;
      }
      else if(entry[score_param]  > 60){
        j++;
      }
      else if(entry[score_param]  > 40){
        k++;
      }
      else if(entry[score_param]  > 20){
        l++;
      }
      else{
        m++;
      }
    });

    let data = [];

    if(m > 0){
      data.push({ name: '0-20', value: m, color: this.props.colors[0]  });
    }

    if(l > 0){
      data.push({ name: '20-40', value: l, color: this.props.colors[1] });
    }

    if(k > 0){
      data.push({ name: '40-60', value: k, color: this.props.colors[2] });
    }

    if(j > 0){
      data.push({ name: '60-80', value: j, color: this.props.colors[3] });
    }

    if(i > 0){
      data.push({ name: '80-100', value: i, color: this.props.colors[4] });
    }
    
    if(n > 0){
      data.push({ name: 'NA', value: n, color: '#efefef'  });
    }

    
    return (
        <PieChart width={210} height={140}>
          <Pie
            data={data}
            cx={100}
            cy={100}
            outerRadius={80}   
            labelLine={false}        
            label={this.renderCustomizedLabel}
            fill="#8884d8"
            dataKey="value"
            startAngle={180}
            endAngle={0}
            innerRadius={60}
            paddingAngle={0}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {
            n > 0 ? <Legend content={this.renderLegend} align="center"/> : <Legend content={this.renderLegend1} align="center"/>
          }
          
        </PieChart>

        
    );
  }
}
