import React from 'react';
import Popover from '@material-ui/core/Popover';
import Selectors from './Selectors.js';
import Icon from "@material-ui/core/Icon";
import Settings from "@material-ui/icons/Settings";


export default function NewSelector(props){

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


    return (
        <div>
            <div  className={window.innerWidth >= 960 ? "floatbtn" : "floatbtn_m"} onClick={handleClick}> 
                Customize view <Icon><Settings/></Icon>
            </div> 

        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        
      >
        <div style={{display:'flex', flexDirection:'column'}}>
                  
                    <Selectors filters={props.filters} changeFilters={props.changeFilters} />
        
        </div>
      </Popover>
      </div>


    )
}