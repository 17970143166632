import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import styles from "../assets/jss/livability/components/tableStyle.js";
import taskStyles from "../assets/jss/livability/components/tasksStyle.js";
import {pillars, indicators} from '../json/pillars_indicators.js';
import {infoColor} from "../assets/jss/livability.js";

import Legend from './Legend.js';
import SearchField from './SearchField';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function descendingComparator_numeric(a, b, orderBy) {

  let scoreA = a[orderBy] ? parseFloat(a[orderBy]) : -Infinity;
  let scoreB = b[orderBy] ? parseFloat(b[orderBy]) : -Infinity;
  if (scoreA === scoreB) return 0;
  return scoreB - scoreA;
}

function getComparator(order, orderBy) {
  if(orderBy.indexOf("score") !== -1){
    return order === 'desc'
    ? (a, b) => descendingComparator_numeric(a, b, orderBy)
    : (a, b) => -descendingComparator_numeric(a, b, orderBy);
  }

  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = (fullwidth, pillar, indicator) => {
  
  const scorefield = 'score';

  const cells = [
    { id: 'rank', numeric: false, disablePadding: false, label: 'Rank', sort: false },
    { id: 'lgd_code', numeric: false, disablePadding: false, label: 'LGD Code', sort: false },
    { id: 'state', numeric: false, disablePadding: false, label: 'State', sort: false },
    { id: 'district', numeric: false, disablePadding: false, label: 'District', sort: false }
  ];


  if(pillar === ""){
    cells.push({ id: scorefield, numeric: true, disablePadding: false, label: 'Overall score⭐',sort: true });
    if(fullwidth){
      pillars.forEach(p => {
        cells.push({ id: p.key+scorefield , numeric: true, disablePadding: false, label: p.value + '⭐',sort: true  });
      });
    }
  }
  else{
    var selectedPillar = pillars.find(p => p.key === pillar);
    cells.push({ id: selectedPillar.key+scorefield, numeric: true, disablePadding: false, label: selectedPillar.value + '⭐', sort: true });
    if(fullwidth){
      indicators[pillar].forEach(i => {
        cells.push({ id: selectedPillar.key+i.key+scorefield+"_raw", numeric: true, disablePadding: false, label: i.value+ '⚡', unit:i.unit, sort: true  });
      });
    }
  }

  
    return cells;
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort,tableHeaderColor } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const createLabel = (lbl) => {
    let indx = lbl.indexOf("deprived");
    if(indx === -1){
      indx = lbl.indexOf("eligible");
      if(indx === -1){
        //return "";
        indx = lbl.indexOf("Climate Change Vulnerability");
        if(indx === -1){
          return "";
        }
        else{
          //return <Legend keyword="Climate Change Vulnerability"/>
          return <a href="https://dst.gov.in/sites/default/files/Full%20Report%20%281%29.pdf" style={{color:infoColor[0]}} target="_blank">📝Climate Change Vulnerability Report</a>
        }
      }
      else{
        return <Legend keyword="Eligible"/>;
      }      
    }
    else{
      return <Legend keyword="Deprived"/>;
    }
  }

  const hcs = headCells(props.fullwidth, props.pillar, props.indicator);
  /* const isNumeric = (str) => {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str)) 
  };
 */
  return (
    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
      <TableRow className={classes.tableHeadRow} key="tblheadrow1">
        {hcs.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id.indexOf("rank") !== -1 ? 'center' : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            { headCell.sort ? 
                <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'desc'} onClick={createSortHandler(headCell.id === "score" ? props.pillar+"score" : headCell.id)}> 
                  {headCell.label}
                </TableSortLabel> : headCell.label

             } 
          </TableCell>
        ))}
      </TableRow>
      {
        props.fullwidth && props.pillar !== "" ?
          [
            <TableRow >
            {hcs.map((headCell) => (
         <TableCell key={headCell.id+`_help`} align="right">
           {createLabel(headCell.label)}
          </TableCell>
         ))}
         
       </TableRow>, <TableRow >
          {hcs.map((headCell) => (
          <TableCell key={headCell.id+`_units`} style={{color: '#686767', fontWeight: 'normal'}} align="right">
           {headCell.unit ? (<i>{headCell.unit}</i>) : null }
           </TableCell>
          ))}

        </TableRow> 
        ] :
      null
      }
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  pillar: PropTypes.string,
};

/* const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
})); */

const EnhancedTableToolbar = (props) => {
  //const classes = useToolbarStyles();
  const goto = (searchString) => {
    //if(searchString.length < 3) return;
   const match = props.itemSet.findIndex(item => item.district.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
   if(match !== -1){
    props.goToPage(null, ~~(match/10));
   }
   props.highlightDist(searchString);
  }

  return (
    <Toolbar style={{display: 'flex', justifyContent: 'flex-end'}} 
      /* className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })} */
    >
     {/*    <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Rankings
        </Typography> */}

        <SearchField onChange={(searchString)=>goto(searchString)} />
    </Toolbar>
  );
};

const useStyles = makeStyles(styles);
const useTaskStyles = makeStyles(taskStyles);

export default function EnhancedTable(props) {

  const scorefield = 'score';

  const classes = useStyles();
  const taskClasses = useTaskStyles();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(props.pillar === "" ? scorefield : props.pillar+scorefield);
  const [searchString, setSearchString] = React.useState("");

  const [page, setPage] = React.useState(0);
  const dense = true;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {tableHeaderColor } = props;

  const handleRequestSort = (event, property) => {
    console.log(orderBy)
    console.log(property)
    console.log(order)
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    props.sortBy(property, isDesc ? 'asc' : 'desc');
  };

  const handleSelectAllClick = (event) => {
    props.selectAll(event.target.checked);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.ranking.length - page * rowsPerPage);
  const sortedSet = stableSort(props.ranking,  getComparator(order, orderBy));

  const sortedSet_dnh = sortedSet.filter(row => row.district === "Dadra and Nagar Haveli");
  const sortedSet_dd = sortedSet.filter(row => row.district === "Diu" || row.district === "Daman");


  const formatValue = (value, unit) => {
    if(unit){
      if(!value){
        return "NA";
      }
      else if(isNaN(value) || value.trim() === ""){
        //return value;
        return "NA";
      }
      else if(unit === "Percentage"){
        return (parseFloat(value)*100).toFixed(2) +"%";
      }
      else if(unit === "Number"){
        return parseFloat(value).toLocaleString('en-IN', {
          maximumFractionDigits: 2
        });
      }
      else if(unit === "Rupees"){
        return parseFloat(value).toLocaleString('en-IN', {
          maximumFractionDigits: 2,
          style: 'currency',
          currency: 'INR'
        });
      }
      else{
        return parseFloat(value).toFixed(2);
      }
    }
    else{
      if(!value){
        return "NA";
      }
      else if(isNaN(value) || value.trim() === ""){
        //return value;
        return "NA"
      }
      else{
        return parseFloat(value).toFixed(2);
      }
    }
    
  };

  const TableRender = () => {
    if(props.isDNHDD){
      return [
        sortedSet.filter((row => row.district === "Dadra and Nagar Haveli"))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {

          const isItemSelected = !row.unselected || !row.unselected;
          
          return (
            <TableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
              className={classes.tableBodyRow}
              style={searchString !== "" && row.district.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ? {backgroundColor:'lightgray'} : {backgroundColor:'white'} }
            >
               <TableCell style={{align:'left'}} key="rnk">
               <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                  <Avatar style={{width:'36px', height:'36px', color: row[props.pillar+scorefield+props.scale] !== null ? props.getColor(row[props.pillar+scorefield+props.scale]).color : "#efefef", backgroundColor:props.getColor(row[props.pillar+scorefield+props.scale]).bgColor}}>
                    {order === 'asc' ? sortedSet.length - ((page * rowsPerPage)+index) : (page * rowsPerPage)+index+1}
                  </Avatar>
                </div>
              </TableCell>

              <TableCell style={{align:'left'}} key="lgdcode">
                {row.lgd_code}
              </TableCell>
              <TableCell style={{align:'left'}} key="state">
                {row.state} {row.state === "Dadra & Nagar Haveli and Daman & Diu" && row.district === "Dadra and Nagar Haveli" ? '🔰' : null}
              </TableCell>
              <TableCell style={{align:'left'}} key="city">
                {row.district}
                
              </TableCell>
              <TableCell align="right" key="score">{row[props.pillar+scorefield+props.scale] && row[props.pillar+scorefield+props.scale] !== "" ? formatValue(row[props.pillar+scorefield+props.scale]) : "NA"}</TableCell>
              {
                props.fullwidth ? 
                props.pillar === "" ? pillars.map(p => <TableCell align="right" key={p.key}>{row[p.key+scorefield+props.scale] !== "" ? formatValue(row[p.key+scorefield+props.scale]) : "NA"}</TableCell>) : 
                indicators[props.pillar].map(i => <TableCell align="right" key={props.pillar+i.key}>{formatValue(row[props.pillar+i.key+scorefield+"_"+props.display], i.unit)}</TableCell>) : null
              }
            </TableRow>
          );
        }),
        sortedSet.filter((row => row.district === "Diu" || row.district === "Daman"))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {

          const isItemSelected = !row.unselected || !row.unselected;
          
          return (
            <TableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
              className={classes.tableBodyRow}
              style={searchString !== "" && row.district.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ? {backgroundColor:'lightgray'} : {backgroundColor:'white'} }
            >
               <TableCell style={{align:'left'}} key="rnk">
               <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                  <Avatar style={{width:'36px', height:'36px', color: props.getColor(row[props.pillar+scorefield+props.scale]).color, backgroundColor:props.getColor(row[props.pillar+scorefield+props.scale]).bgColor}}>
                    {order === 'asc' ? sortedSet.length - ((page * rowsPerPage)+index) : (page * rowsPerPage)+index+1}
                  </Avatar>
                </div>
              </TableCell>

              <TableCell style={{align:'left'}} key="lgdcode">
                {row.lgd_code}
              </TableCell>
              <TableCell style={{align:'left'}} key="state">
                {row.state} {row.state === "Dadra & Nagar Haveli and Daman & Diu" && row.district === "Dadra and Nagar Haveli" ? '🔰' : null}
              </TableCell>
              <TableCell style={{align:'left'}} key="city">
                {row.district}
                
              </TableCell>
              <TableCell align="right" key="score">{row[props.pillar+scorefield+props.scale] && row[props.pillar+scorefield+props.scale] !== "" ? formatValue(row[props.pillar+scorefield+props.scale]) : "NA"}</TableCell>
              {
                props.fullwidth ? 
                props.pillar === "" ? pillars.map(p => <TableCell align="right" key={p.key}>{row[p.key+scorefield+props.scale] !== "" ? formatValue(row[p.key+scorefield+props.scale]) : "NA"}</TableCell>) : 
                indicators[props.pillar].map(i => <TableCell align="right" key={props.pillar+i.key}>{formatValue(row[props.pillar+i.key+scorefield+"_"+props.display], i.unit)}</TableCell>) : null
              }
            </TableRow>
          );
        })

      ]
    }
    return sortedSet
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  const isItemSelected = !row.unselected || !row.unselected;
                  
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className={classes.tableBodyRow}
                      style={searchString !== "" && row.district.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ? {backgroundColor:'lightgray'} : {backgroundColor:'white'} }
                    >
                       <TableCell style={{align:'left'}} key="rnk">
                       <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                          <Avatar style={{width:'36px', height:'36px', color: row[props.pillar+scorefield+props.scale] !== null ? props.getColor(row[props.pillar+scorefield+props.scale]).color : "#efefef", backgroundColor:props.getColor(row[props.pillar+scorefield+props.scale]).bgColor}}>
                            {order === 'asc' ? sortedSet.length - ((page * rowsPerPage)+index) : (page * rowsPerPage)+index+1}
                          </Avatar>
                        </div>
                      </TableCell>

                      <TableCell style={{align:'left'}} key="lgdcode">
                        {row.lgd_code}
                      </TableCell>
                      <TableCell style={{align:'left'}} key="state">
                        {row.state} {row.state === "Dadra & Nagar Haveli and Daman & Diu" && row.district === "Dadra and Nagar Haveli" ? '🔰' : null}
                      </TableCell>
                      <TableCell style={{align:'left'}} key="city">
                        {row.district}
                        
                      </TableCell>
                      <TableCell align="right" key="score">{row[props.pillar+scorefield+props.scale] && row[props.pillar+scorefield+props.scale] !== "" ? formatValue(row[props.pillar+scorefield+props.scale]) : "NA"}</TableCell>
                      {
                        props.fullwidth ? 
                        props.pillar === "" ? pillars.map(p => <TableCell align="right" key={p.key}>{row[p.key+scorefield+props.scale] !== "" ? formatValue(row[p.key+scorefield+props.scale]) : "NA"}</TableCell>) : 
                        indicators[props.pillar].map(i => <TableCell align="right" key={props.pillar+i.key}>{formatValue(row[props.pillar+i.key+scorefield+"_"+props.display], i.unit)}</TableCell>) : null
                      }
                    </TableRow>
                  );
                })
  }

  return (
    <div className={classes.root} key="contdiv">
      <Paper className={classes.paper} style={{backgroundColor:'white'}} key="ppr">
      <EnhancedTableToolbar goToPage={handleChangePage} itemSet={sortedSet} highlightDist={setSearchString} />
        <TableContainer key="tblcont">
          <Table
            className={classes.table}
            size={dense ? 'small' : 'medium'}
            key="tbl"
          >
            <EnhancedTableHead
              key="hdr"
              classes={taskClasses}
              tableHeaderColor={tableHeaderColor}
              numSelected={props.ranking.filter(r => !r.unselected || !r.unselected).length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.ranking.length}
              pillar={props.pillar}
              indicator={props.indicator}
              fullwidth={props.fullwidth}
            />
            <TableBody key="tblbody">
              <TableRender data={sortedSet}/>
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
        key="pagin"
          rowsPerPageOptions={[10]}
          component="div"
          count={props.ranking.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}