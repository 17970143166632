import React, {useEffect} from "react";

// core components
import GridItem from "./GridItem.js";
import GridContainer from "./GridContainer.js";
import Card from "./Card.js";
import CardHeader from "./CardHeader.js";

import EnhancedTable from "./EnhancedTable.js";
import {rqol} from '../json/rqol.js';
import {p1_} from '../json/p1_.js';
import {p2_} from '../json/p2_.js';
import {p3_} from '../json/p3_.js';
import {p4_} from '../json/p4_.js';
import {p5_} from '../json/p5_.js';
import {p6_} from '../json/p6_.js';
import {p7_} from '../json/p7_.js';
import {p8_} from '../json/p8_.js';
import {p9_} from '../json/p9_.js';

import Map from './Map.js';

import BarChart from './BarChart.js';
import Selectors from './NewSelector.js';


import {pillars, indicators} from '../json/pillars_indicators.js';


const colors = { "" : ['#FFFF00', '#FFCC00', '#99CC00', '#008080', '#003300'], "_s" : ['#CCFFFF', '#00CCFF', '#0000FF', '#CC99FF', '#800080'] };  

let viewport = { width: '100%', height: '100%', latitude: 23.551293, longitude: 82.089786, zoom: 3.5  };
let rankings = rqol;

const getHeaders = (pillar) => {
  const hds = []; 
  hds.push({ key: "state", label: "State" });
  hds.push({ key: "district", label: "District" });
  hds.push({ key: "lgd_code", label: "LGD Code" });

  if(pillar === ""){
    hds.push({ key: "score", label: "Overall Score (National scale)" });
    hds.push({ key: "score_s", label: "Overall Score (State scale)" });

    pillars.forEach(p => {
      hds.push({ key: p.key+"score", label: p.value + " (National scale)" });
      hds.push({ key: p.key+"score_s", label: p.value + " (State scale)" });
    });
  }
  else{
    let pl = pillars.find( p => p.key === pillar);
    hds.push({ key: pillar+"score", label: pl.value+" (National scale)" });
    hds.push({ key: pillar+"score_s", label: pl.value+" (State scale)" });

    indicators[pillar].forEach(i => {
      hds.push({ key: pillar+i.key+"score_raw" , label: i.value });
    });

  }

  return hds;
}

// Function to convert the JSON(Array of objects) to CSV.
const arrayToCsv = (headers, data) => {
  const csvRows = [];
   // getting headers. 
  const headerValues = headers.map(header => header.label); 
  csvRows.push(headerValues.join(',')); // Push into array as comma separated values
  // Getting rows. 
  for (const row of data) { 
  const rowValues = headers.map(header => { 
  const escaped = ('' + row[header.key]).replace(/"/g, '\\"'); // To replace the unwanted quotes.
   return `"${escaped}"`; // To escape the comma in a address like string.
   }); 
  csvRows.push(rowValues.join(',')); // Push into array as comma separated values. 
  } 
  return csvRows.join('\n'); // To enter the next rows in the new line '\n' 
  };


  // Function to download the generated CSV as a .csv file.
  const download = (data, fileName) => {
   const blob = new Blob([data], { type: 'text/csv' });
   const url = window.URL.createObjectURL(blob);
   const a = document.createElement('a');
   a.setAttribute('hidden', '');
   a.setAttribute('href', url);
   a.setAttribute('download', fileName + '.csv');
   document.body.appendChild(a);
   a.click();
   document.body.removeChild(a);
  };


  const generateCSV=(pillar, data, filename)=>{
    const header = getHeaders(pillar);
    const csvData=arrayToCsv(header,data);
    download(csvData,filename);
  };

export default function Dashboard(props) {
  const [filters, setFilters] = React.useState({pillar: "", state: "", subset: "", count:"10", display: "map", scale: ""});
  const [ranks, setRanks] = React.useState(rqol.sort(function(a, b) {
    let scoreA = a["score"] ? parseFloat(a["score"]) : -Infinity;
    let scoreB = b["score"] ? parseFloat(b["score"]) : -Infinity;
    if (scoreA === scoreB) return 0;
    return scoreB - scoreA;
  }));

  const [sort, setSort] = React.useState({orderBy: "score", order: 'desc'});

  const changeFilters = (event, value, name) => {
    if(name === "state"){
      if(value === ""){
        setFilters({...filters, "state" : value, "subset" : filters.display !== "chart" ? "" : "top", scale: ""});
        setSort({orderBy: "score", order: 'desc'});
      }
      else{
        setFilters({...filters, "state" : value, "subset" : ""});
        setSort({orderBy: "score", order: 'desc'});
      }
    }
    else if(name === "display"){
      if(value === "chart"){
        setFilters({...filters, "display" : value, "subset" : filters.state === "" ? "top" : "" });
        setSort({orderBy: "score", order: 'desc'});
      }
      else{
        setFilters({...filters, "display" : value, "subset" : ""});
        setSort({orderBy: "score", order: 'desc'});
      }
    }
    else if(name === "subset"){
      setFilters({...filters, "subset" : value});
     /*  if(value !== ""){
        setFilters({...filters, "subset" : value, count: ""});
      }
      else{
        setFilters({...filters, "subset" : value, count: "5"});
      } */
    }
    else if(name === "count"){
      setFilters({...filters, "count" : value});
    }
    else if(name === "pillar"){
      setFilters({...filters, "pillar" : value});
      setSort({orderBy: "score", order: 'desc'});
    }
    else if(name === "scale"){
        setFilters({...filters, "scale" : value});
    }        
  };

  const changeSelection = (id, flag) => {
    const newRanks = ranks.map(obj => {
      if(obj.id === id){
        return ({ ...obj, unselected: !flag });
      }
      else{
        return obj;
      }
    });

    setRanks(newRanks);
  };

  const selectAll = (flag) => {
    setRanks(ranks.map(obj => {
        return ({ ...obj, unselected: !flag });
    }));
  };

  const shortlist = () => {
      if(filters.pillar === "p1_"){
        rankings = p1_;
      }
      else if(filters.pillar === "p2_"){
        rankings = p2_;
      }
      else if(filters.pillar === "p3_"){
        rankings = p3_;
      }
      else if(filters.pillar === "p4_"){
        rankings = p4_;
      }
      else if(filters.pillar === "p5_"){
        rankings = p5_;
      }
      else if(filters.pillar === "p6_"){
        rankings = p6_;
      }
      else if(filters.pillar === "p7_"){
        rankings = p7_;
      }
      else if(filters.pillar === "p8_"){
        rankings = p8_;
      }
      else if(filters.pillar === "p9_"){
        rankings = p9_;
      }
      else{
        rankings = rqol;
      }
  
      const score_param = filters.pillar + 'score';
      let filteredRanks = null;
  
      if(filters.state === ""){
        filteredRanks = rankings.sort(function(a, b) {

          let scoreA = a[score_param] ? parseFloat(a[score_param]) : -Infinity;
          let scoreB = b[score_param] ? parseFloat(b[score_param]) : -Infinity;
          if (scoreA === scoreB) return 0;
          return scoreB - scoreA;

        });
      }
      else{
          const tempRankings = rankings.filter(n => n.state.toLowerCase() === filters.state.toLowerCase());
          filteredRanks = tempRankings.sort(function(a, b) {

          let scoreA = a[score_param] ? parseFloat(a[score_param]) : -Infinity;
          let scoreB = b[score_param] ? parseFloat(b[score_param]) : -Infinity;
          if (scoreA === scoreB) return 0;
          return scoreB - scoreA;

          });
      }


      if(filters.subset === "" || (filters.count !== "" && filteredRanks.length <= parseInt(filters.count))){
        setRanks(filteredRanks);
      }
      else if (filters.subset === "bottom"){
        setRanks(filteredRanks.slice(filteredRanks.length - parseInt(filters.count)));
      }
      else{
        setRanks(filteredRanks.slice(0,parseInt(filters.count)));
      }
    }

  useEffect(() => {
    shortlist();
    // eslint-disable-next-line
  }, [filters])

  const sortBy = (orderBy, order) => {
    setSort({orderBy: orderBy, order: order})
  }

  const getColor = (score) => {
    //const colors = [successColor[0], warningColor[0], dangerColor[0], infoColor[0], infoColor[1]]
    
    if(!score || isNaN(score) ){
      return { bgColor: '#efefef', color: 'black'};
    }
    else if(score > 80){
      //return "rgb(0, 128, 0)";
      return { bgColor: colors[filters.scale][4], color: 'white'};
    }
    else if(score > 60){
      //return "rgb(0, 128, 128)";
      return { bgColor: colors[filters.scale][3], color: 'white'};
    }
    else if(score > 40){
      //return "rgb(153, 204, 0)";
      return { bgColor: colors[filters.scale][2], color: filters.scale === "" ? 'black' : 'white'};
    }
    else if(score > 20){
      //return "rgb(255, 204, 0)";
      return { bgColor: colors[filters.scale][1], color: 'black'};
    }
    else{
      //return "rgb(255, 255, 0)";
      return { bgColor: colors[filters.scale][0], color: 'black'};
  }
}

  return (

     <div >  
            <Selectors filters={filters} changeFilters={changeFilters} />
 
    <GridContainer >
    <GridItem xs={12} md={filters.display === "data" || filters.display === "raw" ? 12 : 6} style={{height:'100%'}}>
    <div style={window.innerWidth >= 960 ? {marginTop:'62px'} : {}}>
       <Card key="crd" style={window.innerWidth >= 960 ? {minHeight: 'calc(80vh - 28px)'} : {minHeight: '80vh'}}>
          <CardHeader key="crdhdr">   
            <EnhancedTable 
              key={filters.pillar+filters.state+filters.show+filters.display}
              tableHeaderColor="warning" 
              ranking={ranks}
              pillar={filters.pillar}
              changeSelection={changeSelection}
              selectAll={selectAll}
              fullwidth={filters.display === "data" || filters.display === "raw"}
              sortBy={sortBy}
              scale={filters.scale}
              display={filters.display}
              isDNHDD={filters.state === "Dadra & Nagar Haveli and Daman & Diu" }
              getColor={getColor}
             />
            
             <div style={{display:'flex', width:'100%', justifyContent:'flex-end', paddingTop:'15px'}}>
              { filters.display === "raw" ? 
                <button onClick={(e) => generateCSV(filters.pillar, ranks, "download")}>Download</button>
              : null }
             </div>

             <div>
                <div> ⭐ These are pillar values which are standardised and scaled. </div>
                {
                  filters.state === "Dadra & Nagar Haveli and Daman & Diu" ?
                  <div>
                    🔰 The states have been shown in the same map but have been analyzed separately.
                  </div> : null
                }
              
                <div> { filters.pillar !== "" && filters.display === "raw" ?  "⚡ These are raw values of the indicators." : null} </div>
                <div> 💡 Click on the column headers to sort. Click again to sort in reverse order.</div>
             </div>

          </CardHeader>
        </Card> 
        </div>
          
    </GridItem>
    { filters.display !== "data" && filters.display !== "raw" ? 
    <GridItem xs={12} md={6} style={{height: "100%"}}>
       <Card><CardHeader key={filters.pillar+filters.state+filters.show+filters.display}>  
         { 
          filters.display === "map" ?
            <Map viewport={viewport} ranking={ranks} state={filters.state} pillar={filters.pillar} show={filters.subset} getColor={getColor} colors={colors[filters.scale]} scale={filters.scale} />: 
            <BarChart ranking={ranks} state={filters.state} pillar={filters.pillar} show={filters.subset} getColor={getColor} sort1={sort} scale={filters.scale} />
         }
         </CardHeader> </Card>
    </GridItem>
    : null }


    </GridContainer>

        
    </div> 
  );
  
}
