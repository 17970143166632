import React from "react";

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import GridItem from "./GridItem.js";
import GridContainer from "./GridContainer.js";
import VirtualizedList from "./VirtualizedList.js";

import {infoColor} from "../assets/jss/livability.js";

import cover_2 from '../assets/img/cover_2.jpg';
import cover_3 from '../assets/img/cover_3.jpg';
import cover_4 from '../assets/img/cover_4.jpg';
import blank from '../assets/img/white.png';


import report from '../assets/img/report.jpg';


export default function Resources() {
    const [hindi, setHindi] = React.useState(false);
    const changeLang = () => {
        setHindi(current => !current)
    }
    function showDoc(filename){
        const path = "https://livabilitystore175634-prod.s3.amazonaws.com/public/docs/"+filename;
        window.open(path, "_blank");
    }

    return (
            <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
            <GridContainer direction="row" justify="space-around" style={{width:'100%'}} >
                <GridItem >
                        <div style={{fontFamily:"Poppins, Roboto", fontSize:'large', fontWeight:'bold', color:infoColor[0], paddingTop:'20px', paddingBottom:'20px'}}>Supplementary Readings</div>
                        <GridContainer spacing={10} justify='flex-start' direction="row"  > 
                            <GridItem>
                                <GridContainer direction="column" >
                                    <GridItem >
                                            <img src={cover_3} alt="Scale" style={{width: '250px', paddingBottom:'5px', cursor:'pointer'}} onClick={() => showDoc('Definition_of_scale.pdf')}/>
                                    </GridItem>
                                    <GridItem >
                                        <div style={{fontFamily:"Poppins, Roboto", fontSize:'small', color:infoColor[0], paddingBottom:'20px', textAlign:'left', cursor:'pointer'}} onClick={() => showDoc('Definition_of_scale.pdf')}>Definition of State Scale<br/> & National Scale</div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem>
                            <GridContainer direction="column" >
                                <GridItem >
                                        <img src={cover_2} alt="Indicator" style={{width: '250px', paddingBottom:'5px', cursor:'pointer'}} onClick={() => showDoc('Indicator_Construct.pdf')} />
                                </GridItem>
                                <GridItem >
                                    <div style={{fontFamily:"Poppins, Roboto", fontSize:'small', color:infoColor[0], paddingBottom:'20px', textAlign:'left', cursor:'pointer'}} onClick={() => showDoc('Indicator_Construct.pdf')}>Indicator Construct</div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        </GridContainer>
                </GridItem>
                <GridItem >
                <GridContainer >
                    <GridItem>
                <div style={{fontFamily:"Poppins, Roboto", fontSize:'large', fontWeight:'bold', color:infoColor[0], paddingTop:'20px', paddingBottom:'20px'}}>Report</div>
                        <GridContainer spacing={10} justify='flex-start' direction="row"  > 
                            <GridItem>
                                <GridContainer direction="column" >
                                    <GridItem >
                                            <img src={report} alt="Scale" style={{width: '250px', paddingBottom:'5px', cursor:'pointer'}} onClick={() => showDoc('RQOL_Report.pdf')}/>
                                    </GridItem>
                                    <GridItem >
                                        <div style={{fontFamily:"Poppins, Roboto", fontSize:'small', color:infoColor[0], paddingBottom:'20px', textAlign:'left', cursor:'pointer'}} onClick={() => showDoc('RQOL_Report.pdf')}>Complete Report</div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem>
                                
                        </GridItem>
                        </GridContainer>
                </GridItem>
                <GridItem >
                    {/* <div style={{fontFamily:"Poppins, Roboto", fontSize:'large', fontWeight:'bold', color:infoColor[0], paddingTop:'20px'}}>State Reports</div>
                    <div style={{fontFamily:"Poppins, Roboto", fontSize:'small', color:infoColor[0], textAlign:'left'}} >Click on a state name below</div>
                    <VirtualizedList showDoc={showDoc}/>    */}  

                     <div style={{fontFamily:"Poppins, Roboto", fontSize:'large', fontWeight:'bold', color:infoColor[0], paddingTop:'20px', paddingBottom:'20px'}}>Data</div>
                    <GridContainer direction="column" >
                        <GridItem >
                                <img src={cover_4} alt="Scale" style={{width: '250px', paddingBottom:'5px', cursor:'pointer'}} onClick={() => showDoc('Raw_Data.pdf')}/>
                        </GridItem>
                    </GridContainer>    
                </GridItem>
                </GridContainer>
                </GridItem>
            </GridContainer>

            <GridContainer direction="row" justify="space-around" style={{width:'100%'}} >
                <GridItem >
                <div style={{fontFamily:"Poppins, Roboto", fontSize:'large', fontWeight:'bold', color:infoColor[0], paddingTop:'20px'}}>Dashboard Tutorial</div>
                    <div style={{fontFamily:"Poppins, Roboto", fontSize:'small', color:infoColor[0], textAlign:'left'}} >
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item style={{fontFamily:"Poppins, Roboto", fontSize:'small', color:infoColor[0], textAlign:'left'}}>English</Grid>
                            <Switch checked={hindi} onChange={changeLang} name="langToggle" color="primary" />
                        <Grid item style={{fontFamily:"Poppins, Roboto", fontSize:'small', color:infoColor[0], textAlign:'left'}}>Hindi</Grid>
                    </Grid>
                    </div>
                    {
                        hindi ?  <iframe width={window.innerWidth >= 960 ? 560 : 250} height="315" src="https://www.youtube.com/embed/nD2RWJWvrxI" title="Rural Quality of Life Dashboard (Hindi)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe> :
                        <iframe width={window.innerWidth >= 960 ? 560 : 250} height="315" src="https://www.youtube.com/embed/3YTxm2egWbw" title="Rural Quality of Life Dashboard" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                    }
                </GridItem>
                <GridItem>
                   {/*  <div style={{fontFamily:"Poppins, Roboto", fontSize:'large', fontWeight:'bold', color:infoColor[0], paddingTop:'20px', paddingBottom:'20px'}}>Data</div>
                    <GridContainer direction="column" >
                        <GridItem >
                                <img src={cover_4} alt="Scale" style={{width: '250px', paddingBottom:'5px', cursor:'pointer'}} onClick={() => showDoc('Raw_Data.pdf')}/>
                        </GridItem>
                    </GridContainer> */}
                </GridItem>
                <GridItem>
                    <div style={{fontFamily:"Poppins, Roboto", fontSize:'large', fontWeight:'bold', color:infoColor[0], paddingTop:'20px', paddingBottom:'20px'}}>  </div>
                    <GridContainer direction="column" >
                        <GridItem >
                                <img src={blank} alt="Scale" style={{width: '250px', paddingBottom:'5px', cursor:'pointer'}} />
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>


            </div>
    );
}