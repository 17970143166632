import Home from "./Components/Home.js";
import Dashboard from "./Components/Dashboard.js";
import Resources from "./Components/Resources.js";

const dashboardRoutes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  
  {
    path: "/resources",
    name: "Resources",
    component: Resources,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },

];


export default dashboardRoutes;
