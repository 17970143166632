/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import lions from '../assets/img/lions.png';
import logo from '../assets/img/logo.png';
import swacch from '../assets/img/swacch.png';
import onefifty from '../assets/img/150.png';
import seventyfive from '../assets/img/75.png';

import tri from '../assets/img/tri.png';
import diu from '../assets/img/diu.png';
import sambodhi from '../assets/img/sambodhi.png';



import styles from "../assets/jss/livability/components/menubarStyle.js";

const useStyles = makeStyles(styles);

export default function Menubar(props) {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, routes } = props;

  var links = (
    <List className={classes.list} >
      {routes.map((prop, key) => {
        var listItemClasses;
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.path)
        });
        
        const blueFontClasses = classNames({
          [" " + classes.blueFont]: activeRoute(prop.path) 
        });

        return (
          <NavLink
            to={prop.path}
            className={classes.item}
            activeClassName="active"
            onClick={props.closeDrawerToggle} 
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, blueFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );

  return (
    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-between', alignItems:'center', paddingTop:'10px'}}>
          <div className={classes.sidebarWrapper1} style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
            <div style={{fontFamily: "Poppins, Roboto", fontSize: 'x-large', color:'white', fontWeight:'bold', padding:'3px', paddingLeft:'10px'}}>
                Rural Quality of Life Dashboard
            </div>
            <div className={classes.sidebarWrapper}>{links}</div>
          </div>
          <div>
            {/* <img src={lions} alt="Ministry of rural development" height="80px" /> */}
            <img src={tri} alt="Transform Rural India" height="40px" style={{paddingBottom:'8px', filter:'brightness(0) invert(1)'}}  />
          </div>
          <div>
            <img src={diu} alt="Development Intelligence Unit" height="40px" style={{paddingLeft:'5px', filter:'brightness(0) invert(1)'}} /> 
          </div>
          <div style={{display:'flex', alignItems:'center'}}>
            <img src={sambodhi} alt="Sambodhi" height="40px" style={{paddingRight:'25px', paddingBottom:'15px', filter:'brightness(0) invert(1)'}} />  
          </div>
    </div>
  );
}

Menubar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
