import React from 'react';
import {Component} from 'react';
import ReactMapGL, {Layer, Source, Popup, NavigationControl, FullscreenControl, Marker} from 'react-map-gl';
import Hidden from "@material-ui/core/Hidden";
import mapdata from '../assets/maps/india-minified.json';
import PierChart from './PierChart.js';
import states from '../json/states.js';
import centroids from '../json/dist-centroid.js';


class Map extends Component {
  constructor(props){
    super(props);
    this.onClick = this.onClick.bind(this);

    this.state = { viewport: this.props.viewport, info: null};

    this.onClick = this.onClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  _updateViewport = viewport => {
    //console.log(viewport);
    this.setState({viewport});
  };

  onClick(event){
    if(event.features && event.features.length > 0){
      this.setState({...this.state, info: { lat: event.lngLat[1], long: event.lngLat[0], state: event.features[0].properties.st_nm, district: event.features[0].properties.district }});
    }
  }

  handleClick(event, lat, long, state, district){
    this.setState({...this.state, info: { lat: lat, long: long, state: state, district: district }});
  }
  
  componentDidMount(){
    if(this.props.state !== ""){
      let selectedState = states.find(s => s.name === this.props.state);
      let newViewport = { width: '100%', height: '100%', latitude: parseFloat(selectedState.lat), longitude: parseFloat(selectedState.long), zoom: parseFloat(selectedState.zoom)  };
      this._updateViewport(newViewport);
    }
  }

  render(){
      const score_param = this.props.pillar + 'score' + this.props.scale;
        const layers = this.props.ranking.map((region) => {
          return (
              <Layer 
                id={region.state + "_" + region.lgd_code} 
                key={region.state + "_" + region.lgd_code}
                type='fill' 
                paint={{'fill-color':this.props.getColor(region[score_param]).bgColor,'fill-opacity': 1, 'fill-outline-color':'black'}} 
                filter= {["all",
                    ["==", "district", region.district],
                    ["==", "st_nm", region.state]
                ]}
              />
            )
        });

        const borders = this.props.state !== "" ?  
          <Layer id={"state_bounds"} key={"state_bounds"} type='line' paint={{'line-color':'black', 'line-width' : 1}} filter= {["==", "st_nm", this.props.state]}/> :
          <Layer id={"dist_bounds"} key={"dist_bounds"} type='line' paint={{'line-color':'black', 'line-width' : 1}} />;

        const layerids = layers.map((l) => l.key);
        const entry = this.state.info !== null ? this.props.ranking.find(r => r.state === this.state.info.state && r.district === this.state.info.district) : null;

        const labels = this.props.state !== "" ? this.props.ranking.map((region) => {
          let centroid = centroids[region.state + " " + region.district];
          if(centroid){
            return (
              <Marker
                key={"m"+region.state + "_" + region.lgd_code}
                longitude={centroid.lat}
                latitude={centroid.long}
                captureDrag={false}
                captureDoubleClick={false}
              >
                <div  
                style={{color:this.props.getColor(region[score_param]).color, fontSize:'x-small', textAlign: 'center', cursor:'pointer'}} onClick={(e) => this.handleClick(e, centroid.long, centroid.lat, region.state, region.district    )}>
                  <span style={{position:'absolute', top:'-10px' , textIndent:'-10px',  whiteSpace:'nowrap'}}>{region.district }</span>
                </div> 
              </Marker>
            )
          }
          else{
            return <div/>;
          }
         
        })
        : <div></div>;

        return (
          <div style={{height: '109.5vh'}}> 
            <ReactMapGL 
                {...this.state.viewport}
                mapboxApiAccessToken="pk.eyJ1IjoicmFhbWFtIiwiYSI6ImNra21ubDhrdDBranEydm5zeDJ6dXB0YmIifQ.GqBEHX5iQdnh2Qndwum5JQ"
                mapStyle="mapbox://styles/raamam/cl0p18vxg00fl15qlhp8qb4sm"
                onViewportChange={this._updateViewport}
                scrollZoom={false}
                interactiveLayerIds={layerids}
                onClick={this.onClick}
                key={this.props.state+this.props.scale} 
            >
              <Source id="dist1" type="geojson" data={mapdata} >
              { layers}
              { labels}
              { 
                this.state.info !== null ?
                <Popup tipSize={5} anchor="top" longitude={this.state.info.long} latitude={this.state.info.lat} closeOnClick={false}  onClose={() => this.setState({...this.state, info: null})} >
                  <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                  <div>LGD Code: {entry && entry.lgd_code ? entry.lgd_code : "??"}</div>
                    <div>State: {this.state.info.state}</div>
                    <div>District: {this.state.info.district}</div>
                    <div>Score: {entry && entry[score_param] ? parseFloat(entry[score_param]).toFixed(2) : "NA"}</div>
                  </div>
                </Popup>  : <div/>
               }

              </Source>
              { this.props.show !== "" ? 
              <Source id="dist2" type="geojson" data={mapdata} >
                {borders}
              </Source>
              : null }
              <div style={{position: 'absolute', top: 0, left: 0, padding: '10px'}}>
                <NavigationControl showCompass={false} /> <br/>
                <FullscreenControl />
              </div>
              <Hidden smDown implementation="css">
                  <div style={{position: 'absolute', top: 0, right: 0, display:'flex', flexDirection:'column', backgroundColor:'white', alignItems:'center'}}>
                  {this.props.show === "" ?
                  <PierChart key={this.props.state+this.props.scale} colors={this.props.colors} ranking={this.props.ranking} pillar={this.props.pillar} scale={this.props.scale} />
                  : null }
                </div>
              </Hidden>
            </ReactMapGL> 
          </div>
        );
  }

}


export default Map;
