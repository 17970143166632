
import React from 'react';
import { Switch, Route} from "react-router-dom";

import "perfect-scrollbar/css/perfect-scrollbar.css";

import { makeStyles } from "@material-ui/core/styles";

import Navbar from "./Components/Navbar.js";

import routes from "./routes.js";
import appStyle from "./assets/jss/livability/layouts/adminStyle.js";

import Sidebar from "./Components/Sidebar.js"
import Footer from './Components/Footer.js';

import bgImage from "./assets/img/sidebar-2.jpg";

const useStyles = makeStyles(appStyle);
const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.path === "/dashboard") {
        return (
          <Route key={key} path={prop.path}
            render={(properties) => (
              <prop.component {...properties} />
            )}  
          />
        );
      }
      else{
        return (
          <Route key={key} path={prop.path} component={prop.component} />
        );
      }
    })}

  </Switch>
);

export default function App({...rest }) {
  const mainPanel = React.createRef();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
 
  const closeDrawerToggle = () => {
    setMobileOpen(false);
  };

  //comment this for live
  /* if(window.location.href.indexOf("rqol.developmentintelligenceunit.in") !== -1){
    return (
      <div className={classes.container} 
                      style={window.location.href.indexOf("/dashboard") === -1 && window.location.href.indexOf("/home") === -1 ? 
                            {display:'flex', flexDirection:'row', justifyContent:'center'} : {}} >
                    {switchRoutes}
                  </div>
    );
  } */
 

  return (
      <div className={classes.wrapper}>
         <Sidebar
            routes={routes}
            image={bgImage}
            logoText={""}
            handleDrawerToggle={handleDrawerToggle}
            closeDrawerToggle={closeDrawerToggle}
            open={mobileOpen}
            color={"blue"}
            {...rest}
         />
            <div className={classes.mainPanel} ref={mainPanel}>
              <Navbar
                routes={routes}
                handleDrawerToggle={handleDrawerToggle}
                closeDrawerToggle={closeDrawerToggle}
                mobileOpen={mobileOpen}
                {...rest}
              />
            
            
              
              <div className={window.innerWidth >= 960 ? classes.content : window.location.href.indexOf("/home") === -1 ? classes.contentm : classes.contentmh} style={window.location.href.indexOf("/home") !== -1 ? {background:'#fff'} : {}}>
                <div className={classes.container} 
                    style={window.location.href.indexOf("/dashboard") === -1 && window.location.href.indexOf("/home") === -1 ? 
                          {display:'flex', flexDirection:'row', justifyContent:'center'} : {}} >
                  {switchRoutes}
                </div>
              </div>
              <div style={{textAlign: 'center'}}><Footer/></div>
            </div> 
      </div>
    ); 
    
}
