import React from 'react';
import {infoColor} from "../assets/jss/livability.js";


export default function Footer(){
    return (
        <div style={{backgroundColor:infoColor[0], color:'white', display:'flex', flexDirection:'column', justifyContent:'space-around', fontFamily: "Poppins, Roboto", fontSize: 'small', margin:'5px'}}>
            <div style={{textAlign:'justify', padding:'10px'}}>© 2022 Development Intelligence Unit. All Rights Reserved.</div>
            <div style={{textAlign:'justify', padding:'10px'}}>The Rural Quality of Life Index has been <a href="https://www.ramu.dev/" target="_blank" style={{color:'white', cursor: 'default'}}>developed by</a> the <a href="https://www.trif.in/diu" target="_blank"style={{color:'yellow'}}>Development Intelligence Unit</a>, an initiative of  <a href="https://www.trif.in/" target="_blank"style={{color:'yellow'}}>Transforming Rural India Foundation</a> and <a href="https://sambodhi.co.in/" target="_blank" style={{color:'yellow'}}>Sambodhi Research and Communications Pvt. Ltd</a>.</div>

        </div>
    )
}