import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import styles from "../assets/jss/livability/components/headerStyle.js";

import routes from "../routes.js";
import Menubar from "./Menubar.js";

import Menu from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";

import {infoColor} from "../assets/jss/livability.js";
import lions from '../assets/img/lions.png';
import logo from '../assets/img/logo.png';

import tri from '../assets/img/tri.png';



const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const colour = "blue";

  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });

  return (
    <AppBar className={classes.appBar + appBarClasses} style={{paddingTop: 0}}>
      <Hidden smDown implementation="css">
      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', backgroundColor: infoColor[0], minHeight:'100px'} }>
            <Menubar
              routes={routes}
              handleDrawerToggle={props.handleDrawerToggle}
              closeDrawerToggle={props.closeDrawerToggle}
              color={colour}
              style={{width:'100%'}}
            />
      </div>
      
      </Hidden>
      <Hidden mdUp implementation="css">
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', backgroundColor: infoColor[0],  alignItems:'center'}}>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', margin:'5px',}}>
            <div style={{fontFamily: "Poppins, Roboto", fontSize: 'medium', width:'150px' , color:'white', paddingLeft:'5px', paddingRight:'5px', fontWeight:'bold', borderRight: '2px solid white'}}>
              Rural Quality of Life Dashboard
            </div>
            <div style={{display:'flex'}}>
              {/* <img src={lions} alt="Ministry of rural development" height="50px" style={{paddingLeft:'5px'}} /> */}
              <img src={tri} alt="Transform Rural India"  height="50px" style={{padding:'3px 3px', filter:'brightness(0) invert(1)'}} />
            </div>
          </div>
          <div>
          <IconButton
            color="inherit"
            onClick={props.handleDrawerToggle}
          >
            <Menu style={{color: 'white'}} />
          </IconButton>
          </div>
        </div>
      </Hidden>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
