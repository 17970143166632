import React, { PureComponent } from 'react';
import {
  ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, 
} from 'recharts';
import {infoColor} from "../assets/jss/livability.js";


export default class Example extends PureComponent {
  
  constructor(props){
    super(props);

    this.state = {
        w: window.innerWidth*0.43, 
        h: window.innerHeight*0.7,  
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }
  
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    this.setState({...this.state, 
      w: window.innerWidth*0.43, 
      h: window.innerHeight*0.7,  
    });
  }

  render() {
    const score_param = this.props.pillar + 'score' + this.props.scale;;
    let selectedRanks = [];

    if(this.props.sort1.orderBy.indexOf("score") !== -1 ){

      selectedRanks = this.props.ranking.sort(function(a, b) {
        let scoreA = a[score_param] ? parseFloat(a[score_param]) : -Infinity;
        let scoreB = b[score_param] ? parseFloat(b[score_param]) : -Infinity;
        if (scoreA === scoreB) return 0;
        return scoreB - scoreA;
      });

      if(this.props.sort1.order === "asc"){
        selectedRanks = selectedRanks.slice().reverse();
      }

    }
    else if(this.props.sort1.orderBy.indexOf("district") !== -1 ){
      if(this.props.sort1.order === "desc"){
        selectedRanks = this.props.ranking.sort(function(a, b) {
          return -1 * a["district"].toLowerCase().localeCompare(b["district"].toLowerCase())
        });
      }
      else{
        selectedRanks = this.props.ranking.sort(function(a, b) {
          return  a["district"].toLowerCase().localeCompare(b["district"].toLowerCase());
        });
      }
    }
    else if(this.props.sort1.orderBy.indexOf("state") !== -1 ){
      if(this.props.sort1.order === "desc"){
        selectedRanks = this.props.ranking.sort(function(a, b) {
          return -1 * a["state"].toLowerCase().localeCompare(b["state"].toLowerCase())
        });
      }
      else{
        selectedRanks = this.props.ranking.sort(function(a, b) {
          return  a["state"].toLowerCase().localeCompare(b["state"].toLowerCase());
        });
      }
    }
    else if(this.props.sort1.orderBy.indexOf("lgd_code") !== -1 ){
      if(this.props.sort1.order === "desc"){
        selectedRanks = this.props.ranking.sort(function(a, b) {
          return -1 * a["lgd_code"].toLowerCase().localeCompare(b["lgd_code"].toLowerCase())
        });
      }
      else{
        selectedRanks = this.props.ranking.sort(function(a, b) {
          return a["lgd_code"].toLowerCase().localeCompare(b["lgd_code"].toLowerCase());
        });
      }
    }

    const data = selectedRanks.map(r => {
          let temp = Object.assign({}, r);
          temp.Score = parseFloat(temp[score_param]).toFixed(2);
          return temp;
    });

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length && payload[0] && payload[0].payload) {
        let pl = payload[0].payload;
        return (
          <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', backgroundColor:'white', padding:'10px'}}>
            <div>LGD Code: {pl.lgd_code}</div>
            <div>State: {pl.state}</div>
            <div>District: {pl.district}</div>
            <div>Score: {pl[this.props.pillar + 'score' + this.props.scale] ? parseFloat(pl[this.props.pillar + 'score' + this.props.scale]).toFixed(2) : "NA"}</div>
          </div>
        );
      }
    
      return null;
    };

    const tickFormatter = (value, index) => {
      const limit = 12; // put your maximum character
      const val = value.toLocaleString().replace(/ /g, '\u00A0')
      if (val.length <= limit) return val;
      return `${val.substring(0, limit)}...`;
   };
   

    return (
      <div style={{height:'109.5vh'}}>
        {
          data.length > 0 ?
          <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width="100%"
            height="100%"
            data={data}
            margin={{
              top: 5, right: 5, left: 50, bottom: 5,
            }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Score" type="number" ticks={[0, 20, 40, 60, 80, 100]}/>
            <YAxis dataKey="district" type="category" interval={this.props.state === "Uttar Pradesh" && this.props.show === "" ? 1 : 0} tickFormatter={tickFormatter}/>
            <Tooltip  content={<CustomTooltip />} />
            {/* <Legend/> */}
            <Bar dataKey="Score" fill={infoColor[0]}  >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={this.props.getColor(entry.Score).bgColor} />
            ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>:
          <div>No data found</div>
        }
        
      </div>
    );
  }
}
